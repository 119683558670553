import React, { useState } from "react"
import styled from "styled-components"
import { Button, Input, FormGroup, Select, MenuItem, TextField } from "@material-ui/core"
import {
    Row,
    Col,
    Label,
} from "reactstrap";
import moment from "moment";
import CyclesAPI from "api/CyclesAPI";
import Cycle from "components/ClassWrapper/Cycle";
import { logErrorGroup } from "Utils";
import { CANCEL_MOTIVE_FR } from "components/ClassWrapper/CancelMotive";

const Container = styled('div')`
    .buttons-container{
        padding-top: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: end;
    }
`

type Props = {
    cycle: Cycle,
    handleCloseAndReload(): void
}

const CancelCycleForm = (props: Props) => {

    const { cycle, handleCloseAndReload } = props
    const [inputDate, setInputDate] = useState(moment().format("YYYY-MM-DD"))
    const [inputMotive, setInputMotive] = useState("")
    const [inputComment, setInputComment] = useState("")

    const handleSubmit = (type: "full" | "limited") => {
        CyclesAPI.cancelCyclesAndAppointments(cycle, type === "full" ? null : moment(inputDate).format("YYYY-MM-DD"), inputMotive === "" ? null : inputMotive, inputComment)
            .then(handleCloseAndReload)
            .catch(error => logErrorGroup(error, "patients.history.cancel_cycle"))
    }

    return (
        <Container>
            <Row style={{ paddingBottom: "20px" }}>
                <Col md={4}>
                    <FormGroup>
                        <Label>Motif d'annulation</Label>
                        <Select value={inputMotive} onChange={e => setInputMotive(e.target.value)}>
                            <MenuItem key={"None"} value={""}><em>Non précisé</em></MenuItem>
                            {Object.entries(CANCEL_MOTIVE_FR)
                                .map(([motive, label]) => <MenuItem key={motive} value={motive}><em>{label}</em></MenuItem>)}
                        </Select>
                    </FormGroup>
                </Col>
                <Col md={8}>
                    <FormGroup>
                        <Label>Commentaire d'annulation</Label>
                        <TextField multiline value={inputComment} onChange={e => setInputComment(e.target.value)}></TextField>
                    </FormGroup>
                </Col>
            </Row>

            <h4>Voulez pouvez annuler le cycle entièrement ou annuler les rendez-vous à partir d'une date.</h4>

            <Row style={{ padding: "20px 0" }}>
                <Col md={4}></Col>
                <Col md={4}>
                    <FormGroup>
                        <Label>Date d'annulation</Label>
                        <Input type='date' value={inputDate} onChange={e => setInputDate(e.currentTarget.value)} />
                    </FormGroup>
                </Col>
            </Row>

            <div className="buttons-container">
                <Button
                    color='primary'
                    variant="outlined"
                    onClick={() => handleSubmit("full")}
                    style={{ marginRight: 20 }}>Annuler entièrement</Button>
                <Button
                    color='primary'
                    variant="contained"
                    onClick={() => { handleSubmit("limited") }}>À partir de la date choisie</Button>
            </div>
        </Container>
    )
}

export default CancelCycleForm