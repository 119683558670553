import config from "config";
import type { Dict } from "Utils";
import { addAllToMap, throwTypeError } from "Utils";
import Motive from "components/ClassWrapper/Motive";
import PageableArray from "components/Controller/PageableArray";
import Sector from "components/ClassWrapper/Sector";
import Protocol from "components/ClassWrapper/Protocol";
import Material from "components/ClassWrapper/Material";
import MaterialType from "components/ClassWrapper/MaterialType";
import Profession from "components/ClassWrapper/Profession";
import Patient from "components/ClassWrapper/Patient";
import Session from "components/ClassWrapper/Session";
import Staff from "components/ClassWrapper/Staff";
import CyclePlanningRequest from "components/LogicClass/CyclePlanningRequest";
import CycleWrapper from "components/ClassWrapper/CycleWrapper";
import Cycle, { CYCLE_STATUS } from "components/ClassWrapper/Cycle";
import OptaConfig from "components/ClassWrapper/OptaConfig";
import type { Credential } from "components/Controller/AuthProvider";
import { authCaller as axiosInstance } from "components/Controller/AuthProvider";
import Account from "components/ClassWrapper/Account";
import CompleteRdv from "components/ClassWrapper/CompleteRdv";
import { Status } from "components/ClassWrapper/RdvStatus";
import Rendezvous from "components/ClassWrapper/Rendezvous";
import type { ModelId } from "components/ClassWrapper/BasicModel";
import type { DateString, TimeString } from "components/ClassWrapper/TimeClasses";
import type PlanningProposal from "components/ClassWrapper/PlanningProposal";
import { AxiosResponse } from "axios";
import { REGEX_DATE_QUERY } from "Constants";
import type { SeatGroup } from "components/ClassWrapper/SeatGroup";
import type { Filter } from "material-table";
import type { CancelMotive } from "components/ClassWrapper/CancelMotive";

export type MTQuery<M> = {
    filters: Filter<M>[],
    pageSize: number,
    page: number,
    orderBy: { field: string },
    orderDirection: string
}
export type MTResponse<M> = {
    data: M[],
    page: number,
    totalCount: number
}

class EntityController<M> {
    /**
     *
     * @param localEndpoint without the trailing slash "/"
     * @param entityCreator the <tt>new</tt> of each entity. Accepts one object arg and return the new entity
     * @param entityRsqlType
     * @param rsqlSearchStrGen search request generator. Accepts one string arg and return a valid rsql search string
     * @param wrapperConverter convert wrapper to acceptable data for server before posting or patching. Default to identity
     */
    constructor<M>(localEndpoint: string,
        entityCreator: (Object) => M,
        entityRsqlType: ?string,
        rsqlSearchStrGen: ?((string) => string),
        wrapperConverter: ((M) => Object) = (x => x)) {
        /**
         *
         * @type {string}
         */
        this.localEndpoint = localEndpoint;
        /**
         * How to spawn new instance of entity class
         * @type {Function<M>}
         */
        this.entityCreator = entityCreator;
        /**
         *
         * @type {?string}
         */
        this.rsqlType = entityRsqlType;
        /**
         * For suggestion searching. <tt>Null</tt> to deactivate
         * @type {?string}
         */
        this.rsqlSearchStrGen = rsqlSearchStrGen;
        /**
         * Default to identity
         * @type {function(M): Object}
         */
        this.wrapperConverter = wrapperConverter;
    }

    getURIForID = (id: number): string => this.localEndpoint + "/" + id;

    /**
     * @param {Object} query
     * @param {number?} query.page starts from 0
     * @param {number?} query.size size of each page
     * @param {string?} query.sort column name with direction
     * @param {string?} query.search search query by rsql
     * @return {Promise<PageableArray<M>>}
     */
    get = ({
        page = config.pagination.defaultPage,
        size = config.pagination.defaultPageSize,
        sort,
        search,
    }): Promise<PageableArray<M>> => {
        let options = { page, size, sort, search };
        ["page", "size", "sort", "search"].forEach(attr => !options[attr] ? delete options[attr] : null);
        return axiosInstance.get(config.rsql, { params: { type: this.rsqlType, ...options } })
            .then(res => res.data)
            .then(data => new PageableArray(
                data["content"].map(this.entityCreator),
                {
                    size: data["size"],
                    totalElements: data["totalElements"],
                    totalPages: data["totalPages"],
                    pageIndex: data["number"],
                }
            ))
    };

    /**
     * Uniquely for material table request with filters
     */
    iQuery = (query: MTQuery<M>,
        searchQuery: string[] = []):
        Promise<MTResponse<M>> =>
        this.get({
            size: query.pageSize,
            page: query.page,
            search: searchQuery.length > 0 ? searchQuery.filter(condition => !!condition && !!condition.trim()).join(";") : "",
            sort: !query.orderBy ? "id,asc" : query.orderBy.field + "," + query.orderDirection
        })
            .then(res => ({
                data: res.array,
                page: res.paging.pageIndex,
                totalCount: res.paging.totalElements,
            }));

    /**
     *
     * @param searchStr hint. Case insensitive
     * @return {Promise<Array<Motive>>}
     */
    searchSuggest = (searchStr: string): Promise<Array<M>> => {
        if (!this.rsqlType
            || typeof this.rsqlSearchStrGen !== "function")
            throw new TypeError("Unsupported method");
        return axiosInstance.get(config.rsql, { params: { type: this.rsqlType, search: this.rsqlSearchStrGen(searchStr) } })
            .then(res => res.data)
            .then(data => data["content"].map(this.entityCreator));
    };

    /**
     *
     * @param newData old data with changes applied, must have the same id
     * @param {number} newData.id should not be <tt>null</tt> or <1
     * @return {Promise<M>} the newly version of entity
     */
    patch = (newData: M): Promise<M> =>
        axiosInstance.patch(this.getURIForID(newData.id), this.wrapperConverter(newData))
            .then(res => res.data)
            .then(this.entityCreator)
        ;

    /**
     *
     * @param oldData
     * @param {number} oldData.id should not be <tt>null</tt> or < 1
     * @return {Promise<void>}
     */
    delete = (oldData: M): Promise<void> =>
        axiosInstance.delete(this.getURIForID(oldData.id));

    /**
     *
     * @param newData
     * @return {Promise<M>}
     */
    post = (newData: M): Promise<M> =>
        axiosInstance.post(this.localEndpoint, this.wrapperConverter(newData))
            .then(res => res.data);
}

class EnumController<M> extends EntityController<M> {
    getAllActive = (disableActiveFilter?: boolean): Array<M> =>
        !!this.rsqlType ?
            axiosInstance.get(config.rsql, {
                params: {
                    type: this.rsqlType,
                    search: !disableActiveFilter ? "enabled==true" : undefined,
                    size: 1000
                }
            })
                .then(res => res.data)
                .then(data => data["content"].map(this.entityCreator))
            :
            axiosInstance.get(this.localEndpoint)
                .then(res => res.data)
                .then(data => !disableActiveFilter ? data["content"].filter(m => m.enabled).map(this.entityCreator) : data["content"].map(this.entityCreator));

    getDict = (disableActiveFilter?: boolean): Promise<Dict<M>> =>
        this.getAllActive(disableActiveFilter)
            .then(addAllToMap);

    getNameDict = (disableActiveFilter?: boolean): Promise<Dict<string>> =>
        this.getAllActive(disableActiveFilter)
            .then(data => {
                let dict = {};
                data.forEach(d => dict[d.id] = d.name);
                return dict;
            });

    post = (_): Promise<M> => throwTypeError("Unsupported api");

    delete = (_): Promise<void> => throwTypeError("Unsupported api");
}

class MaterialController extends EntityController<Material> {
    constructor() {
        super(
            "materials",
            o => new Material(o),
            "material",
            undefined,
            (newData: Material): Object<string, *> => ({
                id: newData.id,
                number: newData.number,
                enabled: !!newData.enabled,
                type: UController.materialType.getURIForID(newData.typeId),
                sector: UController.sector.getURIForID(newData.sectorId),
                externalId: newData.externalId
            })
        );
    }

    getAllActive = (): Promise<Material[]> =>
        axiosInstance.get(config.rsql, {
            params: {
                type: this.rsqlType,
                search: "enabled==true",
                size: 1000
            }
        })
            .then(res => res.data)
            .then(data => data["content"].map(this.entityCreator));
}

class SeatGroupController extends EntityController<SeatGroup> {
    constructor() {
        super(
            "seatGroups",
            o => o,
            "group/seat",
            s => `name=eqnc="*${s.trim().replace(/\s+/, "*")}*",description=eqnc="*${s.trim().replace(/\s+/, "*")}*"`,
            seatGroup => ({
                ...seatGroup,
                seats: seatGroup.seats ? seatGroup.seats.map(s => UController.material.getURIForID(s.id)) : null
            })
        )
    }
}

class PatientController extends EntityController<Patient> {
    constructor() {
        super(
            "patients",
            o => new Patient(o),
            "patient",
            s => REGEX_DATE_QUERY.test(s) ? `birthday==${s}` : `firstName=eqnc="*${s}*",lastName=eqnc="*${s}*",externalId.value=eqnc="*${s}*"`,
            (p: Patient) => ({
                ...p,
                referentDoctor: p.referentDoctor ? UController.staff.getURIForID(p.referentDoctor.id) : undefined,
            })
        );
    }

    getPatientReferentDoctor = (patientId: ModelId): Promise<Staff> => axiosInstance.get(`${this.localEndpoint}/${patientId}/referentDoctor`)
        .then(response => new Staff(response.data));
}

class SessionController extends EntityController<Session> {
    constructor() {
        super(
            "sessions",
            o => new Session(o),
            "session",
            undefined,
            (session: Session) => ({
                ...session,
                range: session.range.horizon,
                intervals: session.intervals.flatMap(
                    s => Array(s.effective).fill({
                        start: s.start,
                        end: s.end
                    })
                ),
            })
        );
    }

    /**
     * @param {Object=} param
     * @param {string?} param.rangeStart conforms ISO_LOCAL_DATE without Z
     * @param {string?} param.rangeEnd conforms ISO_LOCAL_DATE without Z
     * @param {int[]?} param.excludedIds id list to ignore
     * @param {int[]?} param.professionids profession id list to filter
     * @return {Promise<Array<Session>>}
     */
    getAll = ({ rangeStart, rangeEnd, excludedIds, professionIds } = {}) =>
        axiosInstance.get(config.rsql, {
            params: {
                type: "session",
                search: [
                    !!rangeStart ? `range.start=gte=${rangeStart}` : null,
                    !!rangeEnd ? `range.start=lte=${rangeEnd}` : null,
                    !!excludedIds ? `id=out=(${excludedIds.join(",")})` : null,
                    !!professionIds ? `professionId=in=(${professionIds.join(",")})` : null
                ]
                    .filter(condition => !!condition)
                    .join(";")
            }
        })
            .then(res => res.data["content"])
            .then(data => data.map(this.entityCreator));

    /**
     * @param {Array<Session>} sessions
     * @return {Promise<void>}
     */
    postAll = sessions => axiosInstance.post(this.localEndpoint, {
        list: sessions.map(this.wrapperConverter)
    });

    /**
     *
     * @param {Array<Session>} sessions
     * @return {Promise<void>}
     */
    deleteAll = sessions =>
        axiosInstance.delete(this.localEndpoint, {
            params: {
                id: sessions.map(session => session.id)
            }
        });

    /**
     *
     * @param {Array<Session>} sessions
     * @return {Promise<void>}
     */
    patchAll = sessions =>
        axiosInstance.put(this.localEndpoint, {
            list: sessions.map(this.wrapperConverter)
        });
}

const accountEndpoint = "accounts";

class CRUDAccountController extends EntityController<Account> {
    constructor() {
        super(accountEndpoint,
            o => {
                o.role = o.role.includes("ROLE_") ? o.role.replace("ROLE_", "") : o.role
                return new Account(o)
            },
            "account",
            undefined,
            w => ({
                ...w,
                staff: UController.staff.getURIForID(w.staff.id),
            }));
    }

    post = (newData: Account): Promise<Account> =>
        UController.staff.post(newData.staff)
            .then(registeredStaff => {
                return axiosInstance.post(accountEndpoint + "/register",
                    this.wrapperConverter({ ...newData, staff: registeredStaff }))
                    .then(res => res.data)
                    .then(this.entityCreator);
            });

    patch = (newData: Account): Promise<Account> =>
        UController.staff.patch(newData.staff)
            .then(patchedStaff => {
                newData.staff = patchedStaff;
                return axiosInstance.patch(this.getURIForID(newData.id),
                    this.wrapperConverter(newData))
                    .then(res => res.data)
                    .then(this.entityCreator);
            });

    delete = (_): Promise<void> => throwTypeError("Unsupported operation");

    disable = (oldData: Account) =>
        axiosInstance.post(accountEndpoint + "/disable/" + oldData.id)
            .then(() => {
            });
}

const planningEndpoint = "planning/";
const configEndpoint = planningEndpoint + "config/";
const cycleEndpoint = planningEndpoint + "cycles/";
const cycleConfigEndpoint = cycleEndpoint + "config";

export type AVAILABILITY_SYSTEM = "AVAILABILITY_STAFF" | "SESSION";
export type PLANNING_PROFESSION = "DOCTOR" | "NURSE" | "PHARMACIST";
export type ProfessionDescriptor = {
    label: string
}
export const ProfessionDescriptors: { [key: PLANNING_PROFESSION]: ProfessionDescriptor; } = {
    "DOCTOR": {
        label: "Médecin"
    },
    "NURSE": {
        label: "Infirmière"
    },
    "PHARMACIST": {
        label: "Pharmacien"
    }
}

export type PlanningConfig = {
    nursesHandlingSectorMulti: Boolean,
    professionAvailabilitySystemDefault: AVAILABILITY_SYSTEM,
    professionAvailabilitySystem: Map<PLANNING_PROFESSION, AVAILABILITY_SYSTEM>
};

class PlanningController extends EntityController<Cycle> {

    constructor() {
        super(
            cycleEndpoint,
            o => new Cycle(o),
            "cycle"
        );
    }

    /**
     *
     * @param {Array<Cycle>} cyclesToCreate
     * @return {Promise<void>}
     */
    post = (cyclesToCreate: Array<Cycle>): Promise<void> =>
        cyclesToCreate.length > 0 && axiosInstance.post(cycleEndpoint, {
            cycles: cyclesToCreate
        })
        ;

    /**
     *
     * @param {Array<CyclePlanningRequest>} cycleRequests
     * @param {Array<CycleWrapper>} anticipatedOccupations
     * @param {Array<number>} anticipatedFreedom id of rdvs to delete
     * @param {string} from the starting date in iso format
     * @param {string | null} to the ending date in iso format
     * @return {Promise<Array<Cycle>>} created cycles
     */
    postRequests = (cycleRequests: Array<CyclePlanningRequest>,
        anticipatedOccupations: Array<CycleWrapper>,
        anticipatedFreedom: Array<number>,
        from: string, to: string | null): Promise<Array<Cycle>> =>
        cycleRequests.length > 0 ? axiosInstance.post(
            planningEndpoint,
            {
                cycleRequests: cycleRequests.map(req => ({
                    rdvRequests: req.rdvRequests,
                    patient: UController.patient.getURIForID(req.patient.id),
                    motive: req.motive ? UController.motive.getURIForID(req.motive.id) : null,
                    protocol: UController.protocol.getURIForID(req.protocol.id),
                    referentDoctor: req.referentDoctor ? UController.staff.getURIForID(req.referentDoctor.id) : null,
                    comment: req.comment
                })),
                anticipatedOccupations,
                anticipatedFreedom,
            },
            {
                params: {
                    from,
                    to: !!to ? to : undefined
                }
            }
        )
            .then(res => res.data)
            .then(data => data.map(d => {
                let _cycle = new Cycle(d);
                _cycle.registerStatus = CYCLE_STATUS.TO_CREATE;
                _cycle.rendezvousList.forEach(rdv => rdv.registerStatus = CYCLE_STATUS.TO_CREATE);
                return _cycle;
            }))
            : Promise.resolve([]);

    postRequestsWithExplanation = (cycleRequests: CyclePlanningRequest[],
        anticipatedOccupations: CycleWrapper[],
        anticipatedFreedom: number[],
        from: string, to: string | null): Promise<PlanningProposal> =>
        axiosInstance.post(
            planningEndpoint + "proposals",
            {
                cycleRequests: cycleRequests.map(req => ({
                    rdvRequests: req.rdvRequests,
                    patient: UController.patient.getURIForID(req.patient.id),
                    motive: req.motive ? UController.motive.getURIForID(req.motive.id) : null,
                    protocol: UController.protocol.getURIForID(req.protocol.id),
                    referentDoctor: req.referentDoctor ? UController.staff.getURIForID(req.referentDoctor.id) : null,
                    comment: req.comment
                })),
                anticipatedOccupations,
                anticipatedFreedom,
            },
            {
                params: {
                    from,
                    to: !!to ? to : undefined,
                    "explanation.firstDayUnassignment": true,
                }
            }
        )
            .then(res => res.data)
            .then((planningProposal: PlanningProposal) => {
                if (!!planningProposal.cycles)
                    planningProposal.cycles = planningProposal.cycles.map(d => {
                        let _cycle = new Cycle(d);
                        _cycle.registerStatus = CYCLE_STATUS.TO_CREATE;
                        _cycle.rendezvousList.forEach(rdv => rdv.registerStatus = CYCLE_STATUS.TO_CREATE);
                        return _cycle;
                    });
                return planningProposal;
            });

    patch = (cycles: Array<Cycle>): Promise<void> =>
        cycles.length > 0 && axiosInstance.patch(
            cycleEndpoint,
            { cycles }
        );

    delete = (cycles: Array<Cycle>): Promise<void> =>
        cycles.length > 0 && Promise.all(
            cycles.map(cycle => {
                let deleteRdvStarting = cycle.rendezvousList.find(rdv => rdv.registerStatus === CYCLE_STATUS.TO_DELETE);
                let deleteRdvStartingIdx = cycle.rendezvousList.indexOf(deleteRdvStarting);
                return axiosInstance.delete(
                    cycleEndpoint + cycle.id,
                    deleteRdvStartingIdx <= 0 ? undefined : {
                        params: {
                            "from": deleteRdvStarting.sessionDay
                        }
                    }
                );
            })
        );

    /**
     *
     * @returns {Promise<OptaConfig>}
     */
    getConfig = (): Promise<OptaConfig> =>
        axiosInstance.get(configEndpoint)
            .then(res => new OptaConfig(res.data));

    /**
     *
     * @param {string} startDate in ISO format
     * @param {string} endDate in ISO format
     * @return {Promise<Array<Cycle>>} confirmed cycles
     */
    getForInterval = (startDate, endDate): Promise<Array<Cycle>> =>
        axiosInstance.get(cycleEndpoint, {
            params: {
                from: startDate,
                to: endDate
            }
        }).then(res => res.data)
            .then(data => data.map(d => {
                let _cycle = new Cycle(d);
                _cycle.registerStatus = CYCLE_STATUS.CONFIRMED;
                _cycle.rendezvousList.forEach(rdv => rdv.registerStatus = CYCLE_STATUS.CONFIRMED);
                return _cycle;
            }))
        ;

    saveConfig = (config: OptaConfig): Promise<OptaConfig | any> =>
        axiosInstance.patch(configEndpoint, {
            ...config,
            type: OptaConfig.type,
        })
            .then(res => res.data)
            .catch(error => Promise.reject(error.response));

    getCycleConfigRecommended = (): Promise<PlanningConfig> =>
        axiosInstance.get(cycleConfigEndpoint + "/recommended")
            .then(res => res.data);
}

const appointmentEndpoint = "appointments";

class AppointmentController extends EntityController<CompleteRdv> {
    constructor() {
        super(
            appointmentEndpoint,
            CompleteRdv.fromServer
        );
    }

    getCompleteRdv = ({ data }: { data: any }): CompleteRdv => this.entityCreator(data);

    getAllAtDate = (date: ?string = new Date().toISOString().substr(0, 10)): Promise<Array<CompleteRdv>> =>
        this.getAllInPeriod(date, date);

    getAllInPeriod = (from: TimeString, to: TimeString): Promise<Array<CompleteRdv>> =>
        axiosInstance.get(appointmentEndpoint, { params: { from, to } })
            .then(res => res.data.map(this.entityCreator));

    getAllMedPrepableRdvsInPeriod = (from: TimeString, to: TimeString): Promise<Array<CompleteRdv>> =>
        axiosInstance.get(`${appointmentEndpoint}/medPreps`, { params: { from, to } })
            .then(res => res.data.map(this.entityCreator));

    getAllConsultableRendezvousInPeriod = (from: TimeString, to: TimeString): Promise<Array<CompleteRdv>> =>
        axiosInstance.get(`${appointmentEndpoint}/consultations`, { params: { from, to } })
            .then(res => res.data.map(this.entityCreator));

    getStateForAppointIds = (ids: ModelId[]): Promise<Array<CompleteRdv>> =>
        axiosInstance.get(`${appointmentEndpoint}/getState`, { params: { ids } })
            .then(res => res.data["content"].map(this.entityCreator));

    setStatus = (appointId: ModelId,
        status: Status,
        authorization: ?Credential): Promise<CompleteRdv> =>
        axiosInstance.post(`${appointmentEndpoint}/${appointId}/setStatus/${status.id}`, authorization)
            .then(this.getCompleteRdv);

    toggleNurseInCharge = (appointId: ModelId,
        takeOn: boolean,
        authorization: ?Credential): Promise<CompleteRdv> =>
        axiosInstance.post(`${appointmentEndpoint}/${appointId}/setNurse/inCharge/${takeOn}`, authorization)
            .then(this.getCompleteRdv);

    assignNurseInCharge = (appointId: ModelId,
        staffId: ?ModelId): Promise<CompleteRdv> =>
        axiosInstance.post(`${appointmentEndpoint}/${appointId}/assignNurse/${staffId || ""}`)
            .then(this.getCompleteRdv);

    assignDoctorInCharge = (appointId: ModelId,
        staffId: ModelId): Promise<CompleteRdv> =>
        axiosInstance.post(`${appointmentEndpoint}/${appointId}/assignDoctor/${staffId || ""}`)
            .then(this.getCompleteRdv);

    toggleDoctorInCharge = (appointId: ModelId,
        takeOn: boolean): Promise<CompleteRdv> =>
        axiosInstance.post(`${appointmentEndpoint}/${appointId}/setDoctor/inCharge/${takeOn}`)
            .then(this.getCompleteRdv);

    toggleGo = (appointId: ModelId,
        go: boolean): Promise<CompleteRdv> =>
        axiosInstance.post(`${appointmentEndpoint}/${appointId}/setGo/${go}`)
            .then(this.getCompleteRdv);

    startDrugMixing = (appointId: ModelId): Promise<CompleteRdv> =>
        axiosInstance.post(`${appointmentEndpoint}/${appointId}/medPrep/start`)
            .then(this.getCompleteRdv);

    finishDrugMixing = (appointId: ModelId): Promise<CompleteRdv> =>
        axiosInstance.post(`${appointmentEndpoint}/${appointId}/medPrep/finish`)
            .then(this.getCompleteRdv);

    confirmMedReception = (appointId: ModelId): Promise<CompleteRdv> =>
        axiosInstance.post(`${appointmentEndpoint}/${appointId}/medPrep/received`)
            .then(this.getCompleteRdv);

    forcefullyModify = (modifiedData: Rendezvous): Promise<CompleteRdv> =>
        axiosInstance.post(`${appointmentEndpoint}/${modifiedData.id}`, modifiedData)
            .then(this.getCompleteRdv);

    patchComment = (appointId: ModelId, commentPatch: { comment: string }): Promise<CompleteRdv> =>
        axiosInstance.patch(`${appointmentEndpoint}/${appointId}/comment`, commentPatch)
            .then(this.getCompleteRdv);

    cancelRendezvous = (appointId: ModelId, rdvCancel?: { cancelMotive?: CancelMotive, cancelComment?: string }): Promise<CompleteRdv> =>
        axiosInstance.post(`${appointmentEndpoint}/${appointId}/cancel`, rdvCancel)
            .then(this.getCompleteRdv);
}

const protocolEndpoint = "protocols";

class ProtocolController extends EntityController<Protocol> {
    constructor() {
        super(
            protocolEndpoint,
            o => new Protocol(o),
            "protocol",
            s => "name=eqnc=\"*" + s.trim().replace(/\s+/, "*") + "*\"",
            (o: Protocol): Object => ({
                ...o,
                sector: UController.sector.getURIForID(o.sectorId),
            })
        );
    }

    patch = (_) => throwTypeError("Unsupported api");

    put = (newData: Protocol): Promise<Protocol> => axiosInstance.put(this.getURIForID(newData.id), this.wrapperConverter(newData));
}

const saveFile = (response: AxiosResponse, filename: ?string, defaultFilename: ?string) => {
    if (!filename) {
        let contentDisposition = response.headers["content-disposition"];
        if (!!contentDisposition) filename = (contentDisposition.match(/filename\s*=\s*"?(.+)"?/i))[1];
        if (!filename) filename = defaultFilename;
        if (!filename) {
            console.error("No file name specified");
            return;
        }
    }
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }), filename);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
}

class SectorController extends EnumController<Sector> {
    constructor() {
        super(
            "sectors",
            o => new Sector(o),
            "sector",
        )
    }

    /**
     *
     * @param newData
     * @return {Promise<M>}
     */
    post = (newData: M): Promise<M> =>
        axiosInstance.post(this.localEndpoint, this.wrapperConverter(newData))
            .then(res => res.data);

    /**
     *
     * @param newData old data with changes applied, must have the same id
     * @param {number} newData.id should not be <tt>null</tt> or <1
     * @return {Promise<M>} the newly version of entity
     */
    patch = (newData: M): Promise<M> =>
        axiosInstance.patch(this.getURIForID(newData.id), this.wrapperConverter(newData))
            .then(res => res.data)
            .then(this.entityCreator)
        ;
}

class ProtocolsCSVDownloadOptions {
    filename: string = "protocols.csv";
    separator: string = ",";
    charset: string = "UTF-8";
}

class RendezvousCSVDownloadOptions {
    separator: string = ",";
    charset: string = "UTF-8";
    from: DateString;
    to: DateString;
    defaultFilename: string;
}

class DataController {
    rootEndpoint = "/data";

    exportEndpoint = this.rootEndpoint + "/export";

    downloadProtocols = (option: ProtocolsCSVDownloadOptions) => {
        axiosInstance.get(this.exportEndpoint + "/protocols", {
            params: option,
            responseType: "blob"
        })
            .then(response => saveFile(response, option.filename));
    }

    downloadScheduledRendezvous = (option: RendezvousCSVDownloadOptions) =>
        axiosInstance.get(this.exportEndpoint + "/scheduled-rendezvous", {
            params: option,
            responseType: "blob",
        })
            .then(response => saveFile(response, null, option.defaultFilename));
}

/**
 * Universal controller
 */
class UController {
    constructor() {
        throw new TypeError("No constructor allowed");
    }

    /**
     *
     * @type {EntityController<Motive>}
     */
    static motive: EnumController<Motive> = new EntityController(
        "motives",
        o => new Motive(o),
        "motive",
        s => "name=eqnc=\"*" + s.trim() + "*\"",
    );

    /**
     *
     * @type {SectorController}
     */
    static sector = new SectorController()

    /**
     *
     * @type {EnumController<MaterialType>}
     */
    static materialType: EnumController<MaterialType> = new EnumController(
        "materialTypes",
        o => new MaterialType(o),
    );

    /**
     *
     * @type {EnumController<Profession>}
     */
    static profession: EnumController<Profession> = new EnumController(
        "professions",
        o => new Profession(o),
    );

    /**
     *
     * @type {ProtocolController}
     */
    static protocol = new ProtocolController();

    /**
     *
     * @type {MaterialController}
     */
    static material = new MaterialController();

    static seatGroup = new SeatGroupController();

    /**
     *
     * @type {PatientController}
     */
    static patient = new PatientController();

    /**
     *
     * @type {SessionController}
     */
    static session = new SessionController();

    static staff: EntityController<Staff> = new EntityController(
        "staffs",
        o => new Staff(o),
        "staff",
        undefined,
        (data: Staff): Object<string, *> => ({
            ...data,
            sector: !!data.sectorId ? UController.sector.getURIForID(data.sectorId) : undefined,
            profession: !!data.professionId ? UController.profession.getURIForID(data.professionId) : undefined,
        })
    );

    /**
     * CRUD operation controller
     * @type {CRUDAccountController}
     */
    static crudAccount = new CRUDAccountController();

    static planning = new PlanningController();

    static appointment = new AppointmentController();

    static data = new DataController();
}

export default UController;