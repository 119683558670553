import { SERVER_HOST } from "Constants";
import { authCaller } from "components/Controller/AuthProvider";
import MaterialTable from "material-table";
import type { MTQuery } from "components/Controller/UController";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { MATERIAL_TABLE_LOCALIZATION_FR } from "Utils";
import { useRef } from "react";

const LOGGER_API = SERVER_HOST + "/logger/logging";
const KV_SEPARATOR = ":";
const ARG_SEPARATOR = ";";
const Logger = () => {
    const tableRef = useRef();
    return (
        <Row className="m-3">
            <Col>
                <Card outline={true} color="primary">
                    <CardHeader className="d-flex align-items-baseline">
                        <h2 className="text-white mr-auto">
                            Liste des évènements des utilisateurs
                        </h2>
                    </CardHeader>
                    <CardBody className="d-flex flex-column justify-content-start">
                        <MaterialTable
                            tableRef={tableRef}
                            data={(query: MTQuery<M>) => {
                                const filters = query.filters;
                                const searchQuery: String[] = [];
                                // Build search query
                                if (filters.length > 0)
                                    filters.forEach(filter => {
                                        switch (filter.column.field) {
                                            case "action":
                                            case "actor":
                                            case "role":
                                            case "id":
                                                filter.value.trim().length > 0 &&
                                                    searchQuery.push(`${filter.column.field}=eqnc="*${filter.value.trim()}*"`);
                                                break;
                                            case "level":
                                            case "createdByService":
                                                filter.value.length > 0 &&
                                                    searchQuery.push(`${filter.column.field}=in=(${filter.value.join(",")})`);
                                                break;
                                            case "arguments":
                                                filter.value.length > 0 &&
                                                    filter.value.split(ARG_SEPARATOR)
                                                        .forEach(kv => {
                                                            if (!kv.trim()) return;
                                                            const [k, v]: [String, String | null] = kv.trim().split(KV_SEPARATOR);
                                                            if (!!v)
                                                                searchQuery.push(`arguments=hkv=(${k},${v})`);
                                                            else
                                                                searchQuery.push(`arguments=hk=${k}`);
                                                        });
                                                break;
                                            default:
                                                break;
                                        }
                                    });
                                return authCaller.get(LOGGER_API, {
                                    params: {
                                        query: searchQuery.length > 0 ? searchQuery.join(";") : "",
                                        size: query.pageSize,
                                        page: query.page,
                                        sort: !query.orderBy ? "id,asc" : `${query.orderBy.field},${query.orderDirection}`
                                    }
                                })
                                    .then(res => res.data)
                                    .then(data => ({
                                        data: data.content,
                                        page: data.number,
                                        totalCount: data.totalElements,
                                    }));
                            }}
                            columns={[
                                {
                                    field: "createdAt",
                                    title: "Date",
                                    type: "datetime",
                                    filtering: false,
                                    defaultSort: "desc"
                                },
                                {
                                    field: "createdByService",
                                    title: "Service",
                                    type: "string",
                                    lookup: {
                                        "backend": "BACKEND",
                                        "mirth": "MIRTH"
                                    }
                                },
                                {
                                    field: "action",
                                    title: "Action",
                                    type: "string"
                                },
                                {
                                    field: "actor",
                                    title: "Acteur",
                                    type: "string"
                                },
                                {
                                    field: "role",
                                    title: "Rôle",
                                    type: "string"
                                },
                                {
                                    field: "level",
                                    title: "Importance",
                                    type: "string",
                                    lookup: {
                                        "INFO": "INFO",
                                        "SEVERE": "SEVERE",
                                        "ERROR": "ERROR",
                                    }
                                },
                                {
                                    field: "arguments",
                                    title: "Arguments",
                                    render: rowData => (<ul className="p-2 m-0" style={{ width: "100%" }}>
                                        {!rowData.arguments ? "" : Object.entries(rowData.arguments).map(([k, v]) => <li><span>{k}</span>{KV_SEPARATOR}<span>{v}</span></li>)}
                                    </ul>),
                                    sorting: false,
                                    filterPlaceholder: `K1${KV_SEPARATOR}V1${ARG_SEPARATOR}K2${KV_SEPARATOR}V2`
                                },
                                {
                                    field: "actionId",
                                    title: "ID",
                                    type: "string"
                                },
                            ]}
                            options={{
                                exportButton: true,
                                exportFileName: "events",
                                filtering: true,
                                grouping: false,
                                search: false,
                                debounceInterval: 1000,
                                draggable: false,
                                showTitle: false,
                            }}
                            localization={MATERIAL_TABLE_LOCALIZATION_FR}
                            actions={[
                                {
                                    icon: "refresh",
                                    tooltip: "Rafraîchir",
                                    isFreeAction: true,
                                    onClick: () => tableRef.current && tableRef.current.onQueryChange()
                                }
                            ]}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default Logger