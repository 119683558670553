import React, { useState } from "react"
import { Button, FormGroup, Select, MenuItem, TextField } from "@material-ui/core"
import {
    Row,
    Col,
    Label,
} from "reactstrap";
import { logErrorGroup } from "Utils";
import { CANCEL_MOTIVE_FR } from "components/ClassWrapper/CancelMotive";
import UController from "components/Controller/UController";
import Rendezvous from "components/ClassWrapper/Rendezvous";
import CompleteRdv from "components/ClassWrapper/CompleteRdv";


const CallbackCancelRdvForm = (props: {
    onSubmit: (cancelRequest: {
        cancelMotive?: string,
        cancelComment?: string
    }) => void
}) => {
    const { onSubmit } = props
    const [inputMotive, setInputMotive] = useState("")
    const [inputComment, setInputComment] = useState("")

    const handleSubmit = () => onSubmit({
        cancelMotive: inputMotive === "" ? null : inputMotive,
        cancelComment: inputComment === "" ? null : inputComment,
    })

    return (
        <div>
            <Row className="p-2">
                <Col md={4}>
                    <FormGroup>
                        <Label>Motif d'annulation</Label>
                        <Select value={inputMotive} onChange={e => setInputMotive(e.target.value)}>
                            <MenuItem key={"None"} value={""}><em>Non précisé</em></MenuItem>
                            {Object.entries(CANCEL_MOTIVE_FR)
                                .map(([motive, label]) => <MenuItem key={motive} value={motive}><em>{label}</em></MenuItem>)}
                        </Select>
                    </FormGroup>
                </Col>
                <Col md={8}>
                    <FormGroup>
                        <Label>Commentaire d'annulation</Label>
                        <TextField multiline value={inputComment} onChange={e => setInputComment(e.target.value)}></TextField>
                    </FormGroup>
                </Col>
            </Row>

            <div className="p-2 d-flex align-items-center justify-content-end">
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}>
                    Annuler
                </Button>
            </div>
        </div>
    )
}

const CancelRdvForm = (props: {
    rendezvous: Rendezvous,
    handleCloseAndReload: (crdv: CompleteRdv) => any,
    logTag?: string
}) => (
    <CallbackCancelRdvForm
        onSubmit={request => UController.appointment.cancelRendezvous(props.rendezvous.id, {
            cancelComment: request.cancelComment,
            cancelMotive: request.cancelMotive
        })
            .then(props.handleCloseAndReload)
            .catch(error => logErrorGroup(error, props.logTag ?? "patients.history.cancel_rdv"))}
    />
);

export default CancelRdvForm;
export { CallbackCancelRdvForm };