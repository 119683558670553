import { authCaller as axiosInstance } from "components/Controller/AuthProvider";
import Cycle from "components/ClassWrapper/Cycle";
import UController from "components/Controller/UController";
import type { CancelMotive } from "components/ClassWrapper/CancelMotive";

function deleteCyclesAndAppointments(cycleIds: string, from: string) {
    return axiosInstance.delete(`/planning/cycles/${cycleIds}`, { params: { from } })
        .then(response => response.data)
}

function cancelCyclesAndAppointments(cycle: Cycle, from?: string, cancelMotive?: CancelMotive, cancelComment?: string) {
    return Promise.all(cycle.rendezvousList
        .filter(rdv => !from || (rdv.sessionDay >= from))
        .map(rdv => UController.appointment.cancelRendezvous(rdv.id, { cancelMotive, cancelComment }))
    )
}

const CyclesAPI = {
    deleteCyclesAndAppointments,
    cancelCyclesAndAppointments
}

export default CyclesAPI;