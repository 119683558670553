import React from "react";
import "assets/css/OptaRdvStateFlowTable.css";
import {Button, ButtonGroup, Col, Modal, ModalBody, ModalHeader, Row, Table} from "reactstrap";
import CompleteRdv from "components/ClassWrapper/CompleteRdv";
import RdvStatus, {Status} from "components/ClassWrapper/RdvStatus";
import {
    getFullNameWithPrefix,
    getFullNameWithPrefixDoctor,
    getISODateFromISODateTime,
    getLocalDateStrFromISO,
    printIsoDate,
    printIsoDateTime,
    printIsoInterval,
    printIsoTime
} from "Utils";
import Material from "components/ClassWrapper/Material";
import AuthenticationBox from "views/jobs/AuthenticationBox";
import type {Credential} from "components/Controller/AuthProvider";
import {AccountController} from "components/Controller/AuthProvider";
import {highProfiles, PROFESSION_IID_DOCTOR, PROFESSION_IID_NURSE, requiredProfileForAction, ROLE} from "Constants";
import ConfirmBox from "views/jobs/ConfirmBox";
import UController from "components/Controller/UController";
import {Tooltip} from "@material-ui/core";
import AssignmentBox from "views/jobs/AssignmentBox";
import Staff from "components/ClassWrapper/Staff";
import {getStatus, isInSession} from "components/ClassWrapper/Rendezvous";
import {hasEnded, hasStarted, hasStartedNotEnded, reallyExists} from "components/ClassWrapper/TimeClasses";
import styled from "styled-components";
import { generatePDFConvocation } from "tools/pdf_generators";
import { PictureAsPdf } from "@material-ui/icons";
import CancelRdvForm from "components/Forms/CancelRdvForm";
import { RdvCancelLabel } from "components/RdvCancelLabel";

const Container = styled('div')`
    padding: 10px;  
    .header-container{
        display: flex; 
        align-items: center; 
        justify-content: space-between;
        margin-bottom: 10px;
        i{
            margin-right: 10px;
            cursor: pointer;
        }
    }
    .patient-informations{
        padding-top: 35px;
    }
`

const printEntry = (entryName: string, element: React.ReactElement): React.ReactElement => (
        <p className="m-0 p-0"><b className="font-weight-bold">{entryName} :</b>&nbsp;{element}</p>
);

const printRdvStatusIcon = (rdvStatus: Status, key: number = 0): React.ReactNode =>
        <span key={key}>{rdvStatus.icon.map((icon, i) => <i key={i} className={icon}/>)}</span>;

type RdvDetailBoxProps = {
    selectedCompleteRdv: CompleteRdv,
    close():void
}

type RdvDetailBoxState = {
    initialSelectedStaff: Staff,
    assignmentType: "DOCTOR" | "NURSE",
    showAssignmentBox: boolean,
    showAuthBox: boolean,
    showConfirmBox: boolean,
    showCancellationBox: boolean,
    modal: boolean,
    boxMessage: string,
    action: string,
    message: string,
    lock: boolean,
}

class SyncDetailBox extends React.Component {
    props: RdvDetailBoxProps;

    state: RdvDetailBoxState = {
        showAuthBox: false,
        showConfirmBox: false,
        showAssignmentBox: false,
        action: "",
        lock: false,
    };

    closeBox = () => this.setState({
        showAuthBox: false,
        showConfirmBox: false,
        showAssignmentBox: false,
        action: "",
    });

    onConfirm = (credential: ?Credential): void => {
        this.beforeSavingAction();
        this.onConfirmAction[this.state.action](credential)
                .then(this.onSavingSuccess)
                .catch(this.onSavingFailure);
    };

    onConfirmAssignment = (selectedStaff: Staff): void => {
        this.beforeSavingAction();
        this.onConfirmAssigmentAction[this.state.assignmentType](selectedStaff)
                .then(this.onSavingSuccess)
                .catch(this.onSavingFailure);
    }

    onConfirmAction: { [action: string]: (credential: ?Credential) => Promise<CompleteRdv> } = {
        SET_NURSE_ON_SHARED_CONTEXT: (credential) =>
                UController.appointment.toggleNurseInCharge(
                        this.props.selectedCompleteRdv.id,
                        true,
                        credential
                ),
        UNSET_NURSE_ON_SHARED_CONTEXT: (credential) =>
                UController.appointment.toggleNurseInCharge(
                        this.props.selectedCompleteRdv.id,
                        false,
                        credential
                ),
        SET_NURSE: () => UController.appointment.toggleNurseInCharge(
                this.props.selectedCompleteRdv.id,
                true
        ),
        UNSET_NURSE: () => UController.appointment.toggleNurseInCharge(
                this.props.selectedCompleteRdv.id,
                false
        ),
        SET_DOCTOR: () => UController.appointment.toggleDoctorInCharge(
                this.props.selectedCompleteRdv.id,
                true
        ),
        UNSET_DOCTOR: () => UController.appointment.toggleDoctorInCharge(
                this.props.selectedCompleteRdv.id,
                false
        ),
        CONFIRM_PRESENCE: () => UController.appointment.setStatus(
                this.props.selectedCompleteRdv.id,
                RdvStatus.ON_WAIT
        ),
        // No more allowed in BE
        // CONFIRM_CANCELLATION: () => UController.appointment.setStatus(
        //         this.props.selectedCompleteRdv.id,
        //         RdvStatus.CANCELED
        // ),
        CONFIRM_ON_CONSULTATION: () => UController.appointment.setStatus(
                this.props.selectedCompleteRdv.id,
                RdvStatus.ON_CONSULT
        ),
        CONFIRM_GO: () => UController.appointment.toggleGo(
                this.props.selectedCompleteRdv.id,
                true
        ),
        CONFIRM_NO_GO: () => UController.appointment.toggleGo(
                this.props.selectedCompleteRdv.id,
                false
        ),
        CONFIRM_ON_MED_PREP: () => UController.appointment.startDrugMixing(this.props.selectedCompleteRdv.id),
        CONFIRM_DONE_MED_PREP: () => UController.appointment.finishDrugMixing(this.props.selectedCompleteRdv.id),
        CONFIRM_MED_RECEPTION: () => UController.appointment.confirmMedReception(this.props.selectedCompleteRdv.id),
        CONFIRM_ON_INSTALLATION: () => UController.appointment.setStatus(
                this.props.selectedCompleteRdv.id,
                RdvStatus.ON_INSTALL
        ),
        CONFIRM_DONE_INSTALLATION: () => UController.appointment.setStatus(
                this.props.selectedCompleteRdv.id,
                RdvStatus.DONE_INSTALL
        ),
        CONFIRM_ON_TREATMENT: () => UController.appointment.setStatus(
                this.props.selectedCompleteRdv.id,
                RdvStatus.ON_TREATMENT,
        ),
        CONFIRM_DONE_TREATMENT: () => UController.appointment.setStatus(
                this.props.selectedCompleteRdv.id,
                RdvStatus.DONE_TREATMENT
        ),
        CONFIRM_FINISHED: () => UController.appointment.setStatus(
                this.props.selectedCompleteRdv.id,
                RdvStatus.FINISHED
        ),
    };

    onSavingFailure = (e) => {
        console.error(e);
        this.setState({
            lock: false,
            message: "Enregistrement échouée"
        });
    };

    onSavingSuccess = (): void => this.setState({
        lock: false,
        message: "Enregistrement réussie. En cours de recharger",
    });

    beforeSavingAction = () => {
        this.closeBox();
        this.setState({
            lock: true,
            message: "En cours d'enregistrement",
        });
    };

    toggleDoctor = () =>
            this.setState({
                showConfirmBox: true,
                action: !this.props.selectedCompleteRdv.rdv.doctor ? "SET_DOCTOR" : "UNSET_DOCTOR",
                boxMessage: !this.props.selectedCompleteRdv.rdv.doctor ?
                        <span>
                            {`Voulez-vous prendre en charge ${getFullNameWithPrefix(this.props.selectedCompleteRdv.patient)} ?`}
                        </span>
                        :
                        <span>
                            {`Voulez-vous abandonner ${getFullNameWithPrefix(this.props.selectedCompleteRdv.patient)} ?`}
                        </span>,
            });

    toggleNurse = () =>
            this.setState({
                showConfirmBox: true,
                action: !this.props.selectedCompleteRdv.rdv.nurse ? "SET_NURSE" : "UNSET_NURSE",
                boxMessage: !this.props.selectedCompleteRdv.rdv.nurse ?
                        <span>
                            {`Voulez-vous prendre en charge ${getFullNameWithPrefix(this.props.selectedCompleteRdv.patient)} ?`}
                        </span>
                        :
                        <span>
                            {`Voulez-vous abandonner ${getFullNameWithPrefix(this.props.selectedCompleteRdv.patient)} ?`}
                        </span>,
            });

    setNurseOnSharedContext = () =>
            this.setState({
                showAuthBox: true,
                action: "SET_NURSE_ON_SHARED_CONTEXT",
                boxMessage:
                        <span>
                            {`Voulez-vous prendre en charge ${getFullNameWithPrefix(this.props.selectedCompleteRdv.patient)} ?`}
                        </span>,
            });

    unsetNurseOnSharedContext = () =>
            this.setState({
                showAuthBox: true,
                action: "UNSET_NURSE_ON_SHARED_CONTEXT",
                boxMessage:
                        <span>
                            {`Voulez-vous abandonner ${getFullNameWithPrefix(this.props.selectedCompleteRdv.patient)} ?`}
                        </span>,
            });

    markPresence = () =>
            this.setState({
                showConfirmBox: true,
                action: "CONFIRM_PRESENCE",
                boxMessage:
                        <span>
                            Confirmer-vous la&nbsp;<span
                                className="font-weight-bold text-uppercase text-success">présence</span>
                            {` de ${getFullNameWithPrefix(this.props.selectedCompleteRdv.patient)} ?`}
                        </span>,
            });

    markCancellation = () =>
            this.setState({
                showCancellationBox: true
            });

    markOkToGo = () =>
            this.setState({
                showConfirmBox: true,
                action: "CONFIRM_GO",
                boxMessage:
                        <span>
                            Confirmer-vous le <span
                                className="font-weight-bold text-uppercase text-success">feu vert</span>
                            {` de ${getFullNameWithPrefix(this.props.selectedCompleteRdv.patient)} ?`}
                        </span>,
            });

    markNotOkToGo = () =>
            this.setState({
                showConfirmBox: true,
                action: "CONFIRM_NO_GO",
                boxMessage:
                        <span>
                            Refusez-vous le <span
                                className="font-weight-bold text-uppercase text-danger">feu vert</span>
                            {` de ${getFullNameWithPrefix(this.props.selectedCompleteRdv.patient)} ?`}
                        </span>,
            });

    markOnConsultation = () =>
            this.setState({
                showConfirmBox: true,
                action: "CONFIRM_ON_CONSULTATION",
                boxMessage:
                        <span>
                            Confirmez-vous la <span
                                className="font-weight-bold text-uppercase text-success">commencement</span>&nbsp;
                            de la <span
                                className="font-weight-bold text-uppercase text-info">consultation</span>
                            {` de ${getFullNameWithPrefix(this.props.selectedCompleteRdv.patient)} ?`}
                        </span>,
            });

    markOnInstallation = () =>
            this.setState({
                showConfirmBox: true,
                action: "CONFIRM_ON_INSTALLATION",
                boxMessage:
                        <span>
                            Confirmez-vous la <span
                                className="font-weight-bold text-uppercase text-success">commencement</span>&nbsp;
                            de l'<span
                                className="font-weight-bold text-uppercase text-info">installation</span>
                            {` de ${getFullNameWithPrefix(this.props.selectedCompleteRdv.patient)} ?`}
                        </span>,
            });

    markDoneInstallation = () =>
            this.setState({
                showConfirmBox: true,
                action: "CONFIRM_DONE_INSTALLATION",
                boxMessage:
                        <span>
                            Confirmez-vous la <span
                                className="font-weight-bold text-uppercase text-primary">fin</span>&nbsp;
                            de la <span
                                className="font-weight-bold text-uppercase text-info">prise en charge</span>
                            {` de ${getFullNameWithPrefix(this.props.selectedCompleteRdv.patient)} ?`}
                        </span>,
            });

    markOnDrugMixing = () =>
            this.setState({
                showConfirmBox: true,
                action: "CONFIRM_ON_MED_PREP",
                boxMessage:
                        <span>
                            Confirmez-vous la <span
                                className="font-weight-bold text-uppercase text-primary">prise en compte</span>&nbsp;
                            de la <span
                                className="font-weight-bold text-uppercase text-info">préparation de médicament</span>
                            {` pour ${getFullNameWithPrefix(this.props.selectedCompleteRdv.patient)} ?`}
                        </span>,
            });

    markDoneDrugMixing = () =>
            this.setState({
                showConfirmBox: true,
                action: "CONFIRM_DONE_MED_PREP",
                boxMessage:
                        <span>
                            Avez-vous <span
                                className="font-weight-bold text-uppercase text-primary">terminé</span>&nbsp;
                            la <span
                                className="font-weight-bold text-uppercase text-info">préparation de médicament</span>
                            {` pour ${getFullNameWithPrefix(this.props.selectedCompleteRdv.patient)} ?`}
                        </span>,
            });

    markDrugReception = () =>
        this.setState({
            showConfirmBox: true,
            action: "CONFIRM_MED_RECEPTION",
            boxMessage:
                <span>Avez-vous bien <span
                    className="font-weight-bold text-uppercase text-primary">reçu</span>&nbsp;
                    la <span
                    className="font-weight-bold text-uppercase text-info">médicament</span>
                    {` pour ${getFullNameWithPrefix(this.props.selectedCompleteRdv.patient)} ?`}
                        </span>,
        });

    markOnTreatment = () =>
            this.setState({
                showConfirmBox: true,
                action: "CONFIRM_ON_TREATMENT",
                boxMessage:
                        <span>
                            Confirmez-vous la <span
                                className="font-weight-bold text-uppercase text-primary">commencement</span>&nbsp;
                            de la <span
                                className="font-weight-bold text-uppercase text-info">traitement</span>
                            {` de ${getFullNameWithPrefix(this.props.selectedCompleteRdv.patient)} ?`}
                        </span>,
            });

    markDoneTreatment = () =>
            this.setState({
                showConfirmBox: true,
                action: "CONFIRM_DONE_TREATMENT",
                boxMessage:
                        <span>
                            Confirmez-vous la <span
                                className="font-weight-bold text-uppercase text-primary">fin</span>&nbsp;
                            de la <span
                                className="font-weight-bold text-uppercase text-info">traitement</span>
                            {` de ${getFullNameWithPrefix(this.props.selectedCompleteRdv.patient)} ?`}
                        </span>,
            });
    markFinished = () =>
            this.setState({
                showConfirmBox: true,
                action: "CONFIRM_FINISHED",
                boxMessage:
                        <span>
                            Confirmez-vous la <span
                                className="font-weight-bold text-uppercase text-primary">sortie</span>&nbsp;
                            {` de ${getFullNameWithPrefix(this.props.selectedCompleteRdv.patient)} ?`}
                        </span>,
            });

    onFindSuggestion: { [string]: (hint: string) => Promise<Array<Staff>> } = {
        DOCTOR: hint => UController.staff.iQuery(
                {page: 0, pageSize: 5},
                [`profession.internalId==${PROFESSION_IID_DOCTOR}`, 
                `(lastName=like="*${hint.trim()}*",firstName=like="*${hint.trim()}*")`,
                !this.props.selectedCompleteRdv.rdv.doctor ? null : `id!=${this.props.selectedCompleteRdv.rdv.doctor.id}`])
                .then(res => res.data),
        NURSE: hint => UController.staff.iQuery(
                {page: 0, pageSize: 5},
                [`profession.internalId==${PROFESSION_IID_NURSE}`,
                `(lastName=like="*${hint.trim()}*",firstName=like="*${hint.trim()}*")`,
                !this.props.selectedCompleteRdv.rdv.nurse ? null : `id!=${this.props.selectedCompleteRdv.rdv.nurse.id}`])
                .then(res => res.data),
    };

    onConfirmAssigmentAction: { [string]: (selectedStaff: Staff) => Promise<CompleteRdv> } = {
        DOCTOR: selectedStaff => UController.appointment.assignDoctorInCharge(
                this.props.selectedCompleteRdv.id,
                !!selectedStaff ? selectedStaff.id : undefined
        ),
        NURSE: selectedStaff => UController.appointment.assignNurseInCharge(
                this.props.selectedCompleteRdv.id,
                !!selectedStaff ? selectedStaff.id : undefined
        ),
    };

    assignDoctor = () => this.setState({
        showAssignmentBox: true,
        boxMessage: <span>
            {`Veuillez sélectionner un médecin à affecter au patient ${getFullNameWithPrefix(this.props.selectedCompleteRdv.patient)}`}
        </span>,
        assignmentType: "DOCTOR",
        initialSelectedStaff: this.props.selectedCompleteRdv.rdv.doctor,
    });

    assignNurse = () => this.setState({
        showAssignmentBox: true,
        boxMessage: <span>
            {`Veuillez sélectionner une infirmière à affecter au patient ${getFullNameWithPrefix(this.props.selectedCompleteRdv.patient)}`}
        </span>,
        assignmentType: "NURSE",
        initialSelectedStaff: this.props.selectedCompleteRdv.rdv.nurse,
    });

    modifyComment = () => {
        if (!this.props.selectedCompleteRdv || !this.props.selectedCompleteRdv.rdv) return;
        let newComment = window.prompt("Modifier le commentaire :", this.props.selectedCompleteRdv.rdv.comment);
        if (newComment !== null)
            UController.appointment.patchComment(this.props.selectedCompleteRdv.rdv.id, {comment: newComment})
                .catch(console.error);
    };

    shouldComponentUpdate(nextProps: Readonly<RdvDetailBoxProps>, nextState: Readonly<RdvDetailBoxState>, nextContext: any): boolean {
        return (
                // When currently no rdv selected
                (!this.props.selectedCompleteRdv && !!nextProps.selectedCompleteRdv)
                // Another rdv is selected
                || (nextProps.selectedCompleteRdv.id !== this.props.selectedCompleteRdv.id)
                // Update for current rdv
                || (nextProps.selectedCompleteRdv.rdv.lastModifiedDateTime > this.props.selectedCompleteRdv.rdv.lastModifiedDateTime)
                // General state change
                || (nextState !== this.state)
        );
    }

    closeModalCancellation = () => this.setState({showCancellationBox: false});

    render() {
        const {rdv, patient, protocol, motive} = this.props.selectedCompleteRdv;
        const rdvIsModifiable = !hasEnded(rdv.realSession) && !rdv.realCancel;
        const {doctor, nurse} = rdv;
        const status = getStatus(rdv);
        const Box = this.state.showAuthBox ? AuthenticationBox
                : this.state.showConfirmBox ? ConfirmBox
                        : null;
        const account = AccountController.getCurrentAccount();
        const currentUserRole = account.role;
        const hasConsultation = !!rdv.inNeedConsultation;
        const currentUserHasHighProfile = highProfiles.includes(currentUserRole);
        const isAuthorizedToControlConsultation = requiredProfileForAction.MARKING_CONSULTATION.has(currentUserRole) &&
                (!doctor || doctor.id === account.staff.id || currentUserHasHighProfile);
        const hasMedPrep = reallyExists(rdv.medPrep);
        const hasTreatment = reallyExists(rdv.treatment);
        const hasInstallation = reallyExists(rdv.installation);
        return (
            <Container>
                <Modal style={{ maxWidth: '800px', width: '100%' }} isOpen={this.state.showCancellationBox} toggle={this.closeModalCancellation}>
                    <ModalHeader toggle={this.closeModalCancellation}>Annuler le rendez-vous le {printIsoDate(rdv.sessionDay)} de {getFullNameWithPrefix(patient)}</ModalHeader>
                    <ModalBody>
                        <CancelRdvForm
                            rendezvous={rdv}
                            handleCloseAndReload={this.closeModalCancellation}
                        />
                    </ModalBody>
                </Modal>
                {
                    !!Box &&
                    <Box message={this.state.boxMessage}
                            onCancel={this.closeBox}
                            onConfirm={this.onConfirm}
                    />
                }
                {
                    !!this.state.showAssignmentBox &&
                    <AssignmentBox onCancel={this.closeBox}
                                    onConfirm={this.onConfirmAssignment}
                                    message={this.state.boxMessage}
                                    onFindSuggestion={this.onFindSuggestion[this.state.assignmentType]}
                                    initialSelectedStaff={this.state.initialSelectedStaff}
                                    renderStaff={this.state.assignmentType === "DOCTOR" ? getFullNameWithPrefixDoctor : getFullNameWithPrefix}
                    />
                }
                <div className="header-container">
                    <p>
                        <span className='title'>
                            <i className='fas fa-arrow-left' onClick={this.props.close}/>
                            Informations détaillées de&nbsp;<span className="text-underline">{getFullNameWithPrefix(patient)}</span>
                        </span>
                        <span className="mx-2" style={{cursor: "pointer"}} onClick={() => generatePDFConvocation(this.props.selectedCompleteRdv)}><PictureAsPdf /></span>
                    </p>
                    <div className="text-white font-weight-bold">
                        {status.map(s => s.name).join(" | ")}
                        {" "}
                        {status.map((s, i) => printRdvStatusIcon(s, i))}
                    </div>
                </div>
                {
                    this.state.lock &&
                    <Row className="bg-info">
                        <h3 className="text-white float-left my-2 mx-3">
                            {this.state.message}
                            &nbsp;
                            <span><i className="fas fa-spinner"/></span>
                        </h3>
                    </Row>
                }
                <Row>
                    {/* Detailed patient information */}
                    <Col xs={12} md={4} className="patient-informations">
                        {printEntry("IPP", patient?.externalId?.value ?? "N/A")}
                        {printEntry("Date de naissance", patient.birthday ? getLocalDateStrFromISO(patient.birthday) : "")}
                        {patient.physicalInfo?.deceased && printEntry("Décès", patient.physicalInfo?.deceasedAt ? getISODateFromISODateTime(patient.physicalInfo.deceasedAt) : "Oui")}
                        {printEntry("Protocole", protocol.name)}
                        {printEntry("Place", Material.getCompoundName(rdv.place))}
                        {printEntry("Infirmière affectée",
                                <>
                                    {getFullNameWithPrefix(nurse)}
                                    &nbsp;
                                    {
                                        rdvIsModifiable &&
                                        requiredProfileForAction.MARKING_INSTALLATION.has(currentUserRole) &&
                                        (
                                                account.role === ROLE.CARE_GIVER || account.role === ROLE.NURSE ?
                                                        <Button size={"sm"}
                                                                color={nurse ? "danger" : "success"}
                                                                onClick={this.toggleNurse}
                                                        >
                                                            <i className={"fas fa-user-nurse"}/>
                                                        </Button>
                                                        : account.role === ROLE.SHARED_ACCESS ?
                                                        <>
                                                            <Button size={"sm"}
                                                                    color={"success"}
                                                                    onClick={this.setNurseOnSharedContext}
                                                            >
                                                                <i className={"fas fa-user-nurse"}/>
                                                            </Button>
                                                            <Button size={"sm"}
                                                                    color={"danger"}
                                                                    onClick={this.unsetNurseOnSharedContext}
                                                            >
                                                                <i className={"fas fa-user-nurse"}/>
                                                            </Button>
                                                        </>
                                                        : currentUserHasHighProfile && // With higher profile
                                                        <>
                                                            <Button size="sm"
                                                                    color={nurse ? "danger" : "success"}
                                                                    onClick={this.assignNurse}
                                                            >
                                                                <i className={"fas fa-user-nurse"}/>
                                                            </Button>
                                                        </>
                                        )
                                    }
                                </>
                        )}
                        {
                            hasConsultation &&
                            printEntry("Médecin affecté",
                                    <>
                                        {getFullNameWithPrefixDoctor(doctor)}
                                        {
                                            rdvIsModifiable &&
                                            // Allow higher profile to assign a doctor
                                            isAuthorizedToControlConsultation &&
                                            <Button size={"sm"}
                                                    color={!!doctor ? "danger" : "success"}
                                                    onClick={currentUserHasHighProfile ? this.assignDoctor : this.toggleDoctor}
                                            >
                                                <i className={"fas fa-user-md"}/>
                                            </Button>
                                        }
                                    </>)}
                        {printEntry("Motif", !!motive ? motive.name : "")}
                        {printEntry("Note du patient", <><span>{patient.comment}</span></>)}
                        {printEntry("Commentaire du RDV", <><span>{rdv.comment}</span><Button className="mx-2" size="sm" color="primary" onClick={this.modifyComment}><i className="fas fa-pen"/></Button></>)}
                        {rdv.realCancel && printEntry("Annulation", <RdvCancelLabel rdv={rdv}/>)}
                    </Col>
                    {/* Diagram of status */}
                    <Col>
                        <Table responsive striped borderless size="lg"
                                className="rdv-state-flow-table">
                            <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>Horaire envisagé</th>
                                <th>Horaire réel</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th scope="row">Séance complète</th>
                                <td>
                                    {printIsoInterval(rdv.session)}
                                </td>
                                <td>
                                    {printIsoInterval(rdv.realSession)}
                                </td>
                                <td>
                                    {
                                        rdvIsModifiable &&
                                        requiredProfileForAction.MARKING_PRESENCE.has(currentUserRole) &&
                                        <ButtonGroup className="d-flex">
                                            {
                                                (!rdv.realSessionDay || !hasStarted(rdv.realSession))
                                                &&
                                                <Tooltip title={"Marquer la présence"}>
                                                    <Button className="flex-grow-1"
                                                            color="success"
                                                            onClick={this.markPresence}
                                                    >
                                                        {printRdvStatusIcon(RdvStatus.ON_WAIT)}
                                                    </Button>
                                                </Tooltip>
                                            }
                                            <Tooltip title={"Annuler le rdv"}>
                                                <Button className="flex-grow-1"
                                                        color="danger"
                                                        onClick={this.markCancellation}
                                                >
                                                    {printRdvStatusIcon(RdvStatus.CANCELED)}
                                                </Button>
                                            </Tooltip>
                                        </ButtonGroup>
                                    }
                                </td>
                            </tr>
                            {
                                hasConsultation &&
                                <>
                                    <tr>
                                        <th scope="row">Consultation</th>
                                        <td>
                                            {rdv.consultationInPlace && `${printIsoInterval(rdv.consultation)} ${!rdv.consultationInPlace ? printIsoDate(rdv.consultationDay) : ""}`}
                                        </td>
                                        <td>
                                            {`${printIsoInterval(rdv.realConsultation)} ${!rdv.consultationInPlace ? printIsoDate(rdv.realConsultationDay) : ""}`}
                                        </td>
                                        <td>
                                            {
                                                rdvIsModifiable &&
                                                isAuthorizedToControlConsultation &&
                                                (!rdv.consultationInPlace || (isInSession(rdv) && !hasStarted(rdv.realConsultation))) &&
                                                <ButtonGroup className="d-flex">
                                                    {
                                                        !doctor ? // No doctor selected
                                                            <Tooltip
                                                                title={"Affecter un médecin"}>
                                                                <Button className="flex-grow-1"
                                                                        color={"success"}
                                                                        onClick={this.assignDoctor}
                                                                >
                                                                    <i className={"fas fa-user-md"}/>
                                                                </Button>
                                                            </Tooltip>
                                                            : !rdv.realConsultationDay && !hasStarted(rdv.realConsultation) &&
                                                            <Tooltip
                                                                title={"Commencer la consultation"}>
                                                                <Button className="flex-grow-1"
                                                                        color="success"
                                                                        onClick={this.markOnConsultation}
                                                                >
                                                                    {printRdvStatusIcon(RdvStatus.ON_CONSULT)}
                                                                </Button>
                                                            </Tooltip>
                                                    }
                                                </ButtonGroup>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Feu vert</th>
                                        <td colSpan={2} className="text-center">
                                            {
                                                !rdv.goMoment // Decision not made
                                                        ?
                                                        <span className="font-italic">A décider</span> :
                                                        <span className="font-italic">
                                                            {
                                                                rdv.go ?
                                                                        <i className="fas fa-2x fa-check-circle text-success"/> :
                                                                        <i className="fas fa-2x fa-times-circle text-danger"/>
                                                            }
                                                            &nbsp;
                                                            {rdv.goMoment && `${printIsoDateTime(rdv.goMoment)}`}
                                                        </span>
                                            }
                                        </td>
                                        <td>
                                            {
                                                rdvIsModifiable &&
                                                isAuthorizedToControlConsultation &&
                                                hasConsultation &&
                                                !rdv.goMoment &&
                                                hasStarted(rdv.realConsultation) && !!rdv.realConsultationDay &&
                                                <ButtonGroup className="d-flex">
                                                    <Tooltip
                                                            title={"Go et terminer la consultation"}>
                                                        <Button className="flex-grow-1"
                                                                color="success"
                                                                onClick={this.markOkToGo}
                                                        >
                                                        <span>
                                                            <i className="fas fa-check-circle"/>
                                                        </span>
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip
                                                            title={"No go et terminer la consultation"}>
                                                        <Button className="flex-grow-1"
                                                                color="danger"
                                                                onClick={this.markNotOkToGo}
                                                        >
                                                        <span>
                                                            <i className="fas fa-times-circle"/>
                                                        </span>
                                                        </Button>
                                                    </Tooltip>
                                                </ButtonGroup>
                                            }
                                        </td>
                                    </tr>
                                </>
                            }
                            <tr>
                                <th scope="row">Installation</th>
                                <td>{printIsoTime(rdv.installation.start)}</td>
                                <td>{printIsoTime(rdv.realInstallation?.start)}</td>
                                <td>
                                    {
                                        rdvIsModifiable &&
                                        requiredProfileForAction.MARKING_INSTALLATION.has(currentUserRole) &&
                                        isInSession(rdv) && !hasStarted(rdv.realInstallation) &&
                                        <ButtonGroup className="d-flex">
                                            <Tooltip title={"Installer le patient"}>
                                                <Button className="flex-grow-1"
                                                        color="success"
                                                        onClick={this.markOnInstallation}
                                                >
                                                    {printRdvStatusIcon(RdvStatus.ON_INSTALL)}
                                                </Button>
                                            </Tooltip>
                                        </ButtonGroup>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Prise en charge</th>
                                <td>{printIsoInterval(rdv.installation)}</td>
                                <td>{printIsoInterval(rdv.realInstallation)}</td>
                                <td>
                                    {
                                        rdvIsModifiable &&
                                        requiredProfileForAction.MARKING_INSTALLATION.has(currentUserRole) &&
                                        // Patient is installed
                                        hasStartedNotEnded(rdv.realInstallation) &&
                                        <ButtonGroup className="d-flex">
                                            {
                                                !nurse ? // No nurse selected
                                                    account.role === ROLE.CARE_GIVER ?
                                                        <Tooltip
                                                            title={"Prendre en charge le patient"}>
                                                            <Button className="flex-grow-1"
                                                                    color="primary"
                                                                    onClick={this.toggleNurse}
                                                            >
                                                                <i className={"fas fa-user-nurse"}/>
                                                            </Button>
                                                        </Tooltip>
                                                        : account.role === ROLE.SHARED_ACCESS ?
                                                        <Tooltip
                                                            title={"Prendre en charge / Abandonner le patient"}>
                                                            <Button className="flex-grow-1"
                                                                    color={"success"}
                                                                    onClick={this.setNurseOnSharedContext}
                                                            >
                                                                <i className={"fas fa-user-nurse"}/>
                                                            </Button>
                                                            <Button className="flex-grow-1"
                                                                    color={"danger"}
                                                                    onClick={this.unsetNurseOnSharedContext}
                                                            >
                                                                <i className={"fas fa-user-nurse"}/>
                                                            </Button>
                                                        </Tooltip>
                                                        : currentUserHasHighProfile ? // With higher profile
                                                            <Tooltip title={"Assigner une infirmière"}>
                                                                <Button className="flex-grow-1"
                                                                        color="success"
                                                                        onClick={this.assignNurse}
                                                                >
                                                                    <i className={"fas fa-user-nurse"}/>
                                                                </Button>
                                                            </Tooltip>
                                                            : null
                                                    :
                                                    (
                                                        nurse.id === account.staff.id || // Activable only by the affected nurse
                                                        currentUserHasHighProfile // Or by the higher authority
                                                    ) && <Tooltip
                                                        title={"Terminer l'installation et la prise en charge"}>
                                                        <Button className="flex-grow-1"
                                                                color="primary"
                                                                onClick={this.markDoneInstallation}
                                                        >
                                                            {printRdvStatusIcon(RdvStatus.DONE_INSTALL)}
                                                        </Button>
                                                    </Tooltip>
                                            }
                                        </ButtonGroup>
                                    }
                                </td>
                            </tr>
                            {
                                hasMedPrep &&
                                <tr>
                                    <th scope="row">Préparation de médicament</th>
                                    <td>{`${printIsoInterval(rdv.medPrep)} ${rdv.medPrepDay !== rdv.sessionDay ? printIsoDate(rdv.medPrepDay) : ""}`}</td>
                                    <td>{`${printIsoInterval(rdv.realMedPrep)} ${rdv.medPrepDay !== rdv.sessionDay ? printIsoDate(rdv.realMedPrepDay) : ""}`}</td>
                                    <td>
                                        {
                                            rdvIsModifiable &&
                                            requiredProfileForAction.MARKING_MED_PREP.has(currentUserRole) &&
                                            hasStarted(rdv.realSession) && // Patient shows up
                                            (
                                                // If this patient has no need of consultation
                                                !hasConsultation ||
                                                (
                                                    // Else the patient must has consulted and received go light
                                                    !!rdv.goMoment && !!rdv.go
                                                )
                                            ) &&
                                            <ButtonGroup className="d-flex">
                                                {
                                                    // Drug mixing not started
                                                    (!rdv.realMedPrepDay || !rdv.realMedPrep || !rdv.realMedPrep.start) ?
                                                        <Tooltip
                                                            title={"Marquer prise en compte"}>
                                                            <Button className="flex-grow-1"
                                                                    color="success"
                                                                    onClick={this.markOnDrugMixing}
                                                            >
                                                                {printRdvStatusIcon(RdvStatus.ON_MED_PREP)}
                                                            </Button>
                                                        </Tooltip>
                                                        : !rdv.realMedPrep.end ? // Drug mixing not finishes
                                                        <Tooltip title={"Marquer la sortie de pharmacie"}>
                                                            <Button className="flex-grow-1"
                                                                    color="primary"
                                                                    onClick={this.markDoneDrugMixing}
                                                            >
                                                                {printRdvStatusIcon(RdvStatus.DONE_MED_PREP)}
                                                            </Button>
                                                        </Tooltip>
                                                        : null
                                                }
                                            </ButtonGroup>
                                        }
                                    </td>
                                </tr>
                            }
                            {
                                hasMedPrep &&
                                <tr>
                                    <th scope="row">Réception de médicament</th>
                                    <td/>
                                    <td>{printIsoDateTime(rdv.realMedReception)}</td>
                                    <td>
                                        {
                                            rdvIsModifiable &&
                                            requiredProfileForAction.MARKING_MED_RECEPTION.has(currentUserRole) &&
                                            hasEnded(rdv.realMedPrep) && !rdv.realMedReception &&
                                            <ButtonGroup className="d-flex">
                                                <Tooltip
                                                    title={"Marquer la réception de médicament"}>
                                                    <Button className="flex-grow-1"
                                                            color="success"
                                                            onClick={this.markDrugReception}
                                                    >
                                                        {printRdvStatusIcon(RdvStatus.DONE_MED_RECEPTION)}
                                                    </Button>
                                                </Tooltip>
                                            </ButtonGroup>
                                        }
                                    </td>
                                </tr>
                            }
                            {
                                hasTreatment &&
                                <tr>
                                    <th scope="row">Traitement</th>
                                    <td>{printIsoInterval(rdv.treatment)}</td>
                                    <td>{printIsoInterval(rdv.realTreatment)}</td>
                                    <td>
                                        {
                                            rdvIsModifiable &&
                                            requiredProfileForAction.MARKING_TREATMENT.has(currentUserRole) &&
                                            hasEnded(rdv.realInstallation) &&
                                            (!hasMedPrep || (hasEnded(rdv.realMedPrep) && !!rdv.realMedReception)) &&
                                            (!rdv.inNeedConsultation || (!!rdv.goMoment && !!rdv.go)) &&
                                            <ButtonGroup className="d-flex">
                                                {
                                                    !hasStarted(rdv.realTreatment) ?
                                                            <Tooltip
                                                                    title={"Commencer la traitement"}>
                                                                <Button className="flex-grow-1"
                                                                        color="success"
                                                                        onClick={this.markOnTreatment}
                                                                >
                                                                    {printRdvStatusIcon(RdvStatus.ON_TREATMENT)}
                                                                </Button>
                                                            </Tooltip>
                                                            : !rdv.realTreatment.end ?
                                                            <Tooltip
                                                                    title={"Terminer la traitement"}>
                                                                <Button className="flex-grow-1"
                                                                        color="primary"
                                                                        onClick={this.markDoneTreatment}
                                                                >
                                                                    {printRdvStatusIcon(RdvStatus.DONE_TREATMENT)}
                                                                </Button>
                                                            </Tooltip>
                                                            : null
                                                }
                                            </ButtonGroup>
                                        }
                                    </td>
                                </tr>
                            }
                            <tr>
                                <th scope="row">Sortie</th>
                                <td>{printIsoTime(rdv.session.end)}</td>
                                <td>{printIsoTime(rdv.realSession?.end)}</td>
                                <td>
                                    {
                                        rdvIsModifiable &&
                                        requiredProfileForAction.MARKING_FINISHED.has(currentUserRole) &&
                                        isInSession(rdv) &&
                                        (
                                            (!!rdv.goMoment && !rdv.go) ||
                                            (
                                                (!hasConsultation || hasEnded(rdv.realConsultation)) &&
                                                (!hasInstallation || hasEnded(rdv.realInstallation)) &&
                                                (!hasTreatment || hasEnded(rdv.realTreatment))
                                            )
                                        ) &&
                                        <ButtonGroup className="d-flex">
                                            <Tooltip title={"Marquer la sortie"}>
                                                <Button className="flex-grow-1" color="success"
                                                        onClick={this.markFinished}
                                                >
                                                    {printRdvStatusIcon(RdvStatus.FINISHED)}
                                                </Button>
                                            </Tooltip>
                                        </ButtonGroup>
                                    }
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default class DetailBox extends React.PureComponent {
    props: RdvDetailBoxProps;

    render() {
        return <SyncDetailBox selectedCompleteRdv={this.props.selectedCompleteRdv} close={this.props.close}/>;
    }
}