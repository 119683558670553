import { Label, Button } from "reactstrap"
import EntityPicker from "./EntityPicker"
import { getFullNameWithPrefixDoctor } from "Utils"
import { useState, useEffect, useCallback } from "react"
import UController from "components/Controller/UController";
import { styled } from 'styled-components';
import Staff from "components/ClassWrapper/Staff";

const SuggestionItem = styled('div')`
    span{
        display: block;
        &:nth-of-type(1){
            font-weight: 700;
        }
        &:nth-of-type(2){
            font-style: italic;
        }
    }
`

const getFilteredDoctors = (inputValue: String): Promise<Staff[]> => {
    inputValue = inputValue.trim();
    if (inputValue.length === 0)
        return Promise.resolve([]);
    else {
        return UController.staff.iQuery({ page: 0, pageSize: 5 },
            [`(lastName=eqnc="*${inputValue}*",firstName=eqnc="*${inputValue}*",externalId.value=eqnc="*${inputValue}*")`,
                "profession.internalId==DOCTOR"
            ])
            .then(res => res.data);
    }
};

const renderDoctorChoice = (suggestion: Staff) => (
    <SuggestionItem>
        <span>{getFullNameWithPrefixDoctor(suggestion)}</span>
    </SuggestionItem>
);
export const DoctorFilter = ({ onChangeTargetDoctorIds }: {
    onChangeTargetDoctorIds: (doctorIds: number[]) => void
}) => {

    const [targetDoctors, setTargetDoctors]: [Staff[], ((s?: Staff[] | ((ss: Staff[]) => void)) => void)] = useState([]);

    useEffect(() => {
        onChangeTargetDoctorIds(targetDoctors.map(d => d.id))
    }, [targetDoctors, onChangeTargetDoctorIds]);

    const deselectDoctor = useCallback((doctor: Staff) => setTargetDoctors(targetDoctors => targetDoctors.some(d => d.id === doctor.id) ? targetDoctors.filter(d => d.id !== doctor.id) : targetDoctors),
        [setTargetDoctors]);

    const selectDoctor = useCallback((doctor: Staff) => setTargetDoctors(targetDoctors => targetDoctors.every(d => d.id !== doctor.id) ? targetDoctors.concat(doctor) : targetDoctors),
        [setTargetDoctors]);

    return (
        <div>
            <Label>
                <span className="font-weight-bold mr-2">Médecins : </span>
                {
                    targetDoctors.length > 0 &&
                    targetDoctors.map(d => <div className="d-inline-block" key={d.id}>
                        <span className="font-weight-bold text-primary mx-2">{getFullNameWithPrefixDoctor(d)}</span>
                        <Button close size="sm" onClick={() => deselectDoctor(d)} />
                    </div>)
                }
            </Label>
            <EntityPicker
                async
                placeholder={"Recherche par nom/prénom/transcodage"}
                onChooseValue={selectDoctor}
                getFilteredSuggestions={getFilteredDoctors}
                getSuggestionValue={getFullNameWithPrefixDoctor}
                renderSuggestion={renderDoctorChoice}
                clearOnSelect={true}
            />
        </div>
    )
}