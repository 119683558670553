import React from "react";
import {
    compareEnumStringLocalNoCase,
    extractHourAndMinFromISOTime,
    getFullNameWithPrefix,
    getFullNameWithPrefixDoctor,
    getISODateFromLocalDate,
    getLastNames,
    getLocalDateStrFromISO,
    MATERIAL_TABLE_LOCALIZATION_FR,
    printIsoDate,
    reduceDict
} from "Utils";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import UController from "components/Controller/UController";
import Patient from "components/ClassWrapper/Patient";
import Cycle from "components/ClassWrapper/Cycle";
import Rendezvous from "components/ClassWrapper/Rendezvous";
import Material from "components/ClassWrapper/Material";
import AsyncLoaderWrapper from "views/AsyncLoaderWrapper";
import type { OptaEnumDict } from "Utils";
import { PictureAsPdf, HighlightOff, Print } from '@material-ui/icons'
import moment from "moment";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import CancelCycleForm from "components/Forms/CancelCycleForm";
import { AccountController } from "components/Controller/AuthProvider";
import { Card, CardBody, CardHeader } from "reactstrap";
import { styled } from 'styled-components';
import { BED } from "components/ClassWrapper/SeatType";
import { generatePDFConvocation, generatePDFConvocationRdvList } from "tools/pdf_generators";
import { Button } from "reactstrap";
import AssignmentBox from "views/jobs/AssignmentBox";
import Staff from "components/ClassWrapper/Staff";
import memoize from "memoize-one";
import Account from "components/ClassWrapper/Account";
import { REGEX_DATE_INPUT_SEARCH } from "Constants";
import config from "config";
import CancelRdvForm from "components/Forms/CancelRdvForm";
import { RdvCancelLabel } from "components/RdvCancelLabel";

const PatientInfoContainer = styled('div')`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px 0;
`

class CyclesHistory extends React.PureComponent {

    props: {
        selectedPatient: Patient,
        dictionary: OptaEnumDict,
        professionIdDict?: { DOCTOR: ModelId },
    };

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            /**
             * @type {?{value: any, type: "RDV" | "CYCLE"}}
             */
            selectedTarget: null,
            showAuthBox: false,
            showConfirmBox: false,
            showAssignmentBox: false,
            action: "",
            referentDoctor: this.props.selectedPatient.referentDoctor
        }
    }

    tableRef = React.createRef();

    closeModal = () => this.setState({
        modal: false,
        selectedTarget: null
    });

    openModal = (selectedTarget?: {type: "RDV" | "CYCLE", value: any}) => this.setState({
        modal: true,
        selectedTarget
    });

    /**
     *
     * @type {Dict<string>}
     */
    sectorNameDict = reduceDict(this.props.dictionary.sector, "name");

    onDataQuery = query => {
        /**
         *
         * @type {Filter[]}
         */
        let filters = query.filters;
        let searchQuery = ["patient.id==" + this.props.selectedPatient.id];
        // Build search query
        if (filters.length > 0)
            filters.forEach(filter => {
                switch (filter.column.field) {
                    case "protocol.name":
                        if (filter.value && filter.value.trim().length > 0)
                            searchQuery.push("protocol.name=eqnc=\"*" + filter.value.trim() + "*\"");
                        break;
                    case "protocol.sectorId":
                        if (filter.value.length > 0)
                            searchQuery.push("protocol.sector.id=in=(" + filter.value.join(",") + ")");
                        break;
                    case "motive.name":
                        if (filter.value && filter.value.trim().length > 0)
                            searchQuery.push("motive.name=eqnc=\"*" + filter.value.trim() + "*\"");
                        break;
                    case "comment":
                        if (filter.value && filter.value.trim().length > 0)
                            searchQuery.push("comment=eqnc=\"*" + filter.value.trim() + "*\"");
                        break;
                    default:
                        break;
                }
            });
        return UController.planning.iQuery(query, searchQuery);
    };

    renderRendezvousTable = (rowData: Cycle): React.ReactNode => (
        <MaterialTable
            components={{
                Container: props => (
                    <Paper {...props} style={{ maxWidth: "100%", display: "grid" }} />
                )
            }}
            options={{
                toolbar: false,
                search: false,
                grouping: false,
                filtering: false,
                padding: "dense",
                paging: false,
                rowStyle: (rowData: Rendezvous) => ({
                    color: !rowData.realCancel ? undefined : "grey"
                })
            }}
            columns={[
                {
                    field: "id",
                    hidden: true,
                },
                {
                    field: "sessionDay",
                    title: "Date",
                    render: (rowData: Rendezvous): React.ReactElement =>
                        <span>{getLocalDateStrFromISO(rowData.sessionDay)}</span>,
                },
                {
                    field: "session.start",
                    title: "Début",
                    render: (rowData: Rendezvous): React.ReactElement =>
                        <span>{extractHourAndMinFromISOTime(rowData.session.start)}</span>,
                },
                {
                    field: "session.end",
                    title: "Fin",
                    render: (rowData: Rendezvous): React.ReactElement =>
                        <span>{extractHourAndMinFromISOTime(rowData.session.end)}</span>,
                },
                {
                    field: "place",
                    title: "Hébergement",
                    render: (rowData: Rendezvous): React.ReactElement =>
                        <span>{Material.getCompoundName(rowData.place)}</span>,
                },
                {
                    field: "doctor",
                    title: "Médecin",
                    render: (rowData: Rendezvous): React.ReactElement => <span>{Rendezvous.hasPlannedConsultation(rowData) ? getFullNameWithPrefixDoctor(rowData.doctor) : <i className="fas fa-minus-circle text-muted" />}</span>,
                },
                {
                    field: "nurse",
                    title: "Infirmière",
                    render: (rowData: Rendezvous): React.ReactElement => <span>{getFullNameWithPrefix(rowData.nurse)}</span>,
                },
                {
                    field: "comment",
                    title: "Commentaire",
                },
                {
                    title: "Annulé ?",
                    render: (rowData: Rendezvous): React.ReactElement => !rowData.realCancel ? null : <RdvCancelLabel rdv={rowData}/>,
                }
            ]}
            data={rowData.rendezvousList}
            actions={[
                (rowData) => ({
                    icon: () => <Print />,
                    isFreeAction: true,
                    onClick: () => generatePDFConvocation({
                        rdv: rowData,
                        patient: this.props.selectedPatient
                    }),
                    tooltip: "Convocation"
                }),
                (rowData) => ({
                    icon: () => <HighlightOff />,
                    disabled: rowData.realCancel || !this.canCancel(AccountController.getCurrentAccount()) || rowData.sessionDay < moment().format(moment.HTML5_FMT.DATE),
                    isFreeAction: true,
                    onClick: () => this.openModal({ type: "RDV", value: rowData }),
                    tooltip: "Annuler"
                }),
            ]}
        />
    );

    renderPatientInfo(title: string, info: string, disabled?: boolean): ReactElement {
        if (!!info && !disabled)
            return (<p style={{ marginBottom: 0 }}>{title} : <span className="font-weight-bold">{info}</span></p>)
        else
            return (<></>)
    }

    openBoxAssignDoctor = () => this.setState({
        showAssignmentBox: true,
    });

    closeBoxAssignDoctor = () => this.setState({
        showAssignmentBox: false,
    });

    onConfirmAssignDoctor = (selectedStaff: Staff | null): void => {
        this.closeBoxAssignDoctor();
        UController.patient.patch({
            id: this.props.selectedPatient.id,
            referentDoctor: selectedStaff
        })
            .then(() => this.setState({
                referentDoctor: selectedStaff
            }))
            .catch(error => {
                console.error(error);
                // TODO popup
                alert("Une erreur est survenu. Veuillez ressayer ultérieurement");
            });
    };

    onFindSuggestionReferentDoctor = (hint: string): Promise<Array<Staff>> => UController.staff.iQuery(
        { page: 0, pageSize: 5 },
        [
            `profession.id==${this.props.professionIdDict.DOCTOR}`,
            `(lastName=eqnc="*${hint.trim()}*" or firstName=eqnc="*${hint.trim()}*")`
        ])
        .then(res => res.data);

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.selectedPatient !== this.props.selectedPatient) {
            UController.patient.getPatientReferentDoctor(this.props.selectedPatient.id)
                .then(staff => this.setState({
                    referentDoctor: staff
                }))
                .catch(() => this.setState({
                    referentDoctor: null
                }));
            this.tableRef.current && this.tableRef.current.onQueryChange();
        }
    }

    canCancel = memoize((account: Account) => ["ADMIN", "SUPER_ADMIN", "NURSE", "SECRETARIAT"].includes(account.role));

    render() {

        const account = AccountController.getCurrentAccount();
        const canCancel = this.canCancel(account);
        return (
            <>
                <Col xs={12} className="m-2">
                    <Card>
                        <CardHeader>
                            <h2 className="text-white mr-auto">{getFullNameWithPrefix(this.props.selectedPatient)}</h2>
                        </CardHeader>
                        <CardBody>

                            <div style={{ display: "flex", alignItems: "center" }}>
                                {this.renderPatientInfo("Médecin référent", getFullNameWithPrefixDoctor(this.state.referentDoctor, null, "Non assigné"))}
                                <Button size="sm" color="primary" onClick={this.openBoxAssignDoctor} style={{ marginLeft: 10 }}><i className="fas fa-pen" /></Button>
                            </div>

                            <PatientInfoContainer>
                                {this.renderPatientInfo("IPP", this.props.selectedPatient.externalId?.value)}
                                {this.renderPatientInfo("Téléphone", this.props.selectedPatient.externalId?.phone)}
                                {this.renderPatientInfo("Adresse", `${this.props.selectedPatient.contactInfo?.address}, ${this.props.selectedPatient?.contactInfo?.zipCode}, ${this.props.selectedPatient?.contactInfo?.city}`, !this.props.selectedPatient?.contactInfo?.address)}
                                {this.renderPatientInfo("Décès", `${this.props.selectedPatient.physicalInfo?.deceasedAt ? moment(this.props.selectedPatient.physicalInfo?.deceasedAt).format(config.dateTimeFormat) : "Oui"}`, !this.props.selectedPatient.physicalInfo?.deceased)}
                            </PatientInfoContainer>
                            <MaterialTable
                                tableRef={this.tableRef}
                                title={"Historique des soins"}
                                columns={[
                                    {
                                        field: "id",
                                        hidden: true,
                                    },
                                    {
                                        field: "protocol.name",
                                        title: "Protocole",
                                    },
                                    {
                                        field: "protocol.sectorId",
                                        title: "Spécialité",
                                        lookup: this.sectorNameDict,
                                    },
                                    {
                                        field: "motive.name",
                                        title: "Motif",
                                    },
                                    {
                                        title: "Date de début",
                                        type: "date",
                                        field: "interval.start",
                                        sorting: true,
                                        filtering: false,
                                    },
                                    {
                                        title: "Date de fin",
                                        type: "date",
                                        field: "interval.end",
                                        defaultSort: "desc",
                                        sorting: true,
                                        filtering: false,
                                    },
                                    {
                                        title: "Commentaire",
                                        field: "comment",
                                        sorting: false
                                    }
                                ]}
                                detailPanel={[
                                    {
                                        tooltip: "Afficher les rendezvous inclus",
                                        render: this.renderRendezvousTable
                                    }
                                ]}
                                data={this.onDataQuery}
                                options={{
                                    draggable: false,
                                    search: false,
                                    filtering: true,
                                }}
                                actions={[
                                    (rowData) => ({
                                        icon: () => <PictureAsPdf />,
                                        isFreeAction: true,
                                        onClick: () => generatePDFConvocationRdvList(rowData),
                                        tooltip: "Liste des rendez-vous"
                                    }),
                                    (rowData) => ({
                                        icon: () => <HighlightOff />,
                                        disabled: !canCancel || rowData.interval.end < moment().format(moment.HTML5_FMT.DATE),
                                        isFreeAction: true,
                                        onClick: () => this.openModal({ type: "CYCLE", value: rowData }),
                                        tooltip: "Annuler"
                                    }),
                                ]}
                                localization={MATERIAL_TABLE_LOCALIZATION_FR} />
                        </CardBody>
                    </Card>
                </Col>
                <Modal style={{ maxWidth: '800px', width: '100%' }} isOpen={this.state.modal} toggle={this.closeModal}>
                    {
                        this.state.selectedTarget?.type === "CYCLE" ?
                            <>
                                <ModalHeader toggle={this.closeModal}>Annuler le cycle de {getFullNameWithPrefix(this.props.selectedPatient)}</ModalHeader>
                                <ModalBody>
                                    <CancelCycleForm
                                        cycle={this.state.selectedTarget.value}
                                        handleCloseAndReload={() => {
                                            this.tableRef.current.onQueryChange()
                                            this.closeModal();
                                        }} />
                                </ModalBody>
                            </>
                            : this.state.selectedTarget?.type === "RDV" ?
                                <>
                                    <ModalHeader toggle={this.closeModal}>Annuler le rendez-vous le {printIsoDate(this.state.selectedTarget.value.sessionDay)} de {getFullNameWithPrefix(this.props.selectedPatient)}</ModalHeader>
                                    <ModalBody>
                                        <CancelRdvForm
                                            rendezvous={this.state.selectedTarget.value}
                                            handleCloseAndReload={() => {
                                                this.tableRef.current.onQueryChange()
                                                this.closeModal();
                                            }} />
                                    </ModalBody>
                                </>
                                : null
                    }
                </Modal>
                {
                    !!this.state.showAssignmentBox &&
                    <AssignmentBox
                        onCancel={this.closeBoxAssignDoctor}
                        onConfirm={this.onConfirmAssignDoctor}
                        message={<span>
                            {`Veuillez sélectionner un médecin à affecter au patient ${getFullNameWithPrefix(this.props.selectedPatient)}`}
                        </span>}
                        onFindSuggestion={this.onFindSuggestionReferentDoctor}
                        initialSelectedStaff={this.state.referentDoctor} />
                }
            </>
        );
    }
}

class PatientList extends React.PureComponent {

    props: {
        onSelectPatient: Patient => void,
    };

    state = {
        /**
         * @type Patient
         */
        selectedPatient: null,
    };

    onDataQuery = (query) => {
        /**
         *
         * @type {Filter[]}
         */
        let filters = query.filters;
        // Build search query
        let searchQuery = [];
        if (filters.length > 0)
            filters.forEach(filter => {
                switch (filter.column.field) {
                    case "firstName":
                    case "contactInfo.address":
                    case "contactInfo.zipCode":
                    case "contactInfo.city":
                    case "contactInfo.phone":
                    case "externalId.value":
                    case "comment":
                        filter.value.trim().length > 0 &&
                            searchQuery.push(filter.column.field + "=eqnc=\"*" + filter.value.trim() + "*\"");
                        break;
                    case "lastNames":
                        filter.value.trim().length > 0 &&
                            searchQuery.push(`(lastName=eqnc="*${filter.value.trim()}*",partnerName=eqnc="*${filter.value.trim()}*")`);
                        break;
                    case "sex":
                        filter.value.length > 0 &&
                            searchQuery.push(filter.column.field + "=in=(" + filter.value.join(",") + ")");
                        break;
                    case "birthday":
                        const str = filter.value.trim();
                        str.length > 0 && REGEX_DATE_INPUT_SEARCH.test(str) &&
                            searchQuery.push(`birthday==(${getISODateFromLocalDate(str)})`);
                        break;
                    default:
                        break;
                }
            });
        // Fix query.orderBy
        if (query.orderBy?.field === "lastNames")
            query.orderBy.field = "lastName";
        return UController.patient.iQuery(query, searchQuery).then(patientPage => ({
            ...patientPage,
            data: patientPage.data.map(p => ({
                ...p,
                bedRequirement: p.seatTypeRequirement === BED
            }))
        }));
    };

    onSelectPatient = (event, rowData: Patient) =>
        this.setState({
            selectedPatient: rowData,
        }, () => this.props.onSelectPatient(rowData));

    onRowUpdate = (newData: Patient) => UController.patient.patch({
        ...newData,
        seatTypeRequirement: newData.bedRequirement ? BED : null,
        comment: newData.comment,
    });

    render() {
        return (
            <Col xs={12} className="m-2">
                <MaterialTable
                    title={"Liste des patients"}
                    columns={[
                        {
                            field: "lastNames",
                            title: "Nom(s)",
                            filtering: true,
                            editable: "never",
                            render: (rowData: Patient): React.ReactElement => <span>{getLastNames(rowData)}</span>
                        },
                        {
                            field: "firstName",
                            title: "Prénom",
                            filtering: true,
                            editable: "never",
                        },
                        {
                            field: "sex",
                            title: "Sexe",
                            lookup: { "M": "M", "F": "F" },
                            filtering: true,
                            editable: "never",
                        },
                        {
                            field: "birthday",
                            title: "Date de naissance",
                            render: (rowData: Patient): React.ReactElement =>
                                <span>{!!rowData.birthday ? getLocalDateStrFromISO(rowData.birthday) : ""}</span>,
                            editable: "never",
                            filtering: true,
                            filterPlaceholder: "JJ/MM/YYYY"
                        },
                        {
                            field: "externalId.value",
                            title: "IPP",
                            filtering: true,
                            editable: "never",
                        },
                        // {
                        //     field: "contactInfo.address",
                        //     title: "Adresse",
                        //     filtering: true,
                        //     editable: "never",
                        // },
                        // {
                        //     field: "contactInfo.zipCode",
                        //     title: "Code postal",
                        //     filtering: true,
                        //     editable: "never",
                        // },
                        {
                            field: "contactInfo.city",
                            title: "Ville",
                            filtering: true,
                            editable: "never",
                        },
                        {
                            field: "contactInfo.phone",
                            title: "N° phone",
                            filtering: true,
                            editable: "never",
                        },
                        {
                            field: "bedRequirement",
                            title: "Lit requis",
                            filtering: true,
                            type: "boolean",
                            editable: "onUpdate",
                            initialEditValue: false,
                        },
                        {
                            field: "comment",
                            title: "Note",
                            filtering: true,
                            editable: "onUpdate",
                        },
                    ]}
                    data={this.onDataQuery}
                    options={{
                        exportButton: true, exportDelimiter: ';',
                        exportFileName: Patients.title,
                        debounceInterval: 500,
                        grouping: false,
                        draggable: false,
                        filtering: true,
                        search: false
                    }}
                    editable={{
                        onRowUpdate: this.onRowUpdate,
                    }}
                    onRowClick={this.onSelectPatient}
                    localization={MATERIAL_TABLE_LOCALIZATION_FR}
                />
            </Col>
        );
    }
}

class SyncPatients extends React.PureComponent {

    state = {
        selectedPatient: null,
    };

    props: {
        dictionary?: OptaEnumDict,
        professionIdDict?: { DOCTOR: ModelId },
    };

    static defaultProps = {};

    render() {
        return <Row className="m-2">
            <PatientList onSelectPatient={this.selectPatient} />
            {
                !!this.state.selectedPatient &&
                <CyclesHistory
                    selectedPatient={this.state.selectedPatient}
                    dictionary={this.props.dictionary}
                    professionIdDict={this.props.professionIdDict}
                />
            }
        </Row>;
    }

    selectPatient = rowData => this.setState({ selectedPatient: rowData });
}

class Patients extends React.PureComponent {
    render() {
        return (
            <AsyncLoaderWrapper loader={() => Promise.all([
                UController.sector.getDict(),
                UController.materialType.getDict(),
                UController.profession.getNameDict()
            ]).then(([sectorDict, materialTypeDict, professionDict]) => {
                const ids = Object.keys(professionDict);
                return ({
                    dictionary: {
                        sector: sectorDict,
                        materialType: materialTypeDict,
                    },
                    professionIdDict: {
                        DOCTOR: ids.find(id => compareEnumStringLocalNoCase("Médecin", professionDict[id]))
                    }
                })
            }
            )}
                onLoadingMessage={"En cours de chargement de la liste de patients"}
            >
                <SyncPatients />
            </AsyncLoaderWrapper>
        );
    }
}

export default Patients;