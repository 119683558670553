import { CYCLE_STATUS } from "components/ClassWrapper/Cycle";

const RegisterStatusSymbol = ({ registerStatus }: {
    registerStatus?: string
}) => (
    registerStatus === CYCLE_STATUS.TO_DELETE ? <span className="text-danger font-weight-bold">(S)</span>
        : (registerStatus === CYCLE_STATUS.TO_MODIFY) ? <span className="text-orange font-weight-bold">(M)</span>
            : (registerStatus === CYCLE_STATUS.TO_CREATE) && <span className="text-success font-weight-bold">(A)</span>
);

export default RegisterStatusSymbol;